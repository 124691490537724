.section {
   &__contact {
      padding: 21px 0;
      display: none;
      @media (max-width: 480px) {
         display: block;
      }
   }
}
.contact {
   &__header {
      display: flex;
      align-items: center;
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 19px;
      line-height: 1.14;
      color: #3677f5;
      margin-bottom: 4px;
      svg {
         width: 8px;
         margin-top: 3px;
         margin-right: 10px;
      }
   }
   &__img {
      max-width: 171px;
      width: 100%;
      height: 100%;
      margin: 0 auto 9px;
      border-radius: 50% !important;
      img {
         border-radius: 50% !important;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   &__title {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 31px;
      line-height: 1.15;
      color: #000000;
      text-align: center;
      margin-bottom: 12px;
   }
   &__menu {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 34px;
      margin-bottom: 13px;
      border-bottom: 1px solid #d9d9d9;
   }
   &__menu-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #4779ed;
      border-radius: 50%;
      width: 43px;
      height: 43px;
      margin-right: 40px;
      @media (max-width: 350px){
         margin-right: 20px;
      }
      &:last-child {
         margin-right: 0;
      }
      img {
        width: 22px;
        height: auto;
      }
      &.disabled{
         background: #D8D8D8;
         pointer-events: none;
      }
   }
   &__item {
      padding-bottom: 16px;
      margin-bottom: 19px;
      border-bottom: 1px solid #F0F0F0;
   }
   &__item-title {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 1.14;
      color: #000000;
      margin-bottom: 14px;
   }
   &__item-text {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 19px;
      line-height: 1.14;
      color: #000000;
   }
   &__item-link {
      color: #4779ed !important;
   }
   &__item-subtext {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 17px;
      line-height: 1.16;
      color: #000000;
   }
   &__item-cancel {
      color: #EA3526;
   }
}
