.section {
   &__albom {
      background: #000000;
      padding: 2px 3px 37px;
      min-height: 100vh;
      height: 100%;
      display: none;
      @media (max-width: 480px) {
         display: block;
      }
   }
}
.albom {
   position: relative;
   &__header {
      display: flex;
      align-items: center;
      position: sticky;
      padding-left: 24px;
      top: 20px;
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 19px;
      line-height: 1.14;
      color: #ffffff;
      img{
         width: 10px;
         margin-top: 3px;
         margin-right: 10px;
      }
   }
   &__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2px;
      @media (max-width: 400px){
         grid-template-columns: repeat(2, 1fr);
      }
   }
   &__item {
      width: 100%;
      img{
         width: 100%;
      }
   }
}
