.section {
   &__desctop {
      padding: 4vh 0;
      height: 100vh;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      &.hide {
         display: none !important;
      }
      @media (max-width: 480px) {
         display: none !important;
      }
   }
}
.desctop {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   &__logo {
      width: auto;
      height: 9vh;
      margin-bottom: 4vh;
      img {
         width: auto;
         height: 100%;
      }
   }
   &__content {
      text-align: center;
      margin-bottom: 4vh;
   }
   &__text {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 2vh;
      line-height: 1.14;
      color: #000000;
      margin-bottom: 0.6vh;
   }
   &__img {
      width: auto;
      height: 35vh;
      margin-bottom: 1vh;
      img {
         width: auto;
         height: 100%;
      }
   }
   &__qrcode {
      width: auto;
      height: 13vh;
      img {
         width: auto;
         height: 100%;
      }
   }
   &__subtext {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 1.2vh;
      line-height: 1.15;
      color: #000000;
      text-align: center;
      margin-top: 7vh;
   }
}
