.section {
   &__rotate {
      padding: 100px 0;
      min-height: 100vh;
      height: 100%;
      display: none;
      align-items: center;
      &.active {
         display: flex;
      }
   }
}
.rotate {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   height: 100%;
   min-height: 230px;
   padding-bottom: 40px;
   &__logo {
      transform: rotate(-90deg);
      max-width: 300px;
      width: 100%;
      height: 100%;
      @media (max-width: 1110px) {
         max-width: 230px;
      }
      @media (max-width: 768px) {
         max-width: 180px;
      }
      @media (max-width: 480px) {
         margin-left: -30px;
      }
      img {
         width: 100%;
         height: auto;
      }
   }
   &__content {
      @media (max-width: 480px) {
         margin-left: -20px;
      }
   }
   &__text {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 30px;
      line-height: 1.15;
      color: #000000;
      @media (max-width: 768px) {
         font-size: 25px;
      }
      @media (max-width: 480px) {
         font-size: 22px;
      }
   }
   &__subtext {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 1.13;
      text-align: right;
      color: #000000;
      position: absolute;
      right: 0;
      bottom: 0;
   }
}
