@mixin font-face($name, $file, $weight: 400, $style: normal) {
	@font-face {
		font-family: "#{$name}";
		src: local("#{$file}"), url("../fonts/#{$file}.woff2") format("woff2"),
			url("../fonts/#{$file}.woff") format("woff");
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}
/* Brakpoints */
$minwidth: 320px; 
$mw: 1200px;
$md1: $mw + 12;
$md2: 1110px;
$md3: 991.98px;
$md4: 767.98px;
$md5: 479.98px;
/* Colors */
