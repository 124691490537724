*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	position: relative;
}

body {
	min-height: 100vh;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-x: hidden !important;
	font-style: normal;
	background: #ffffff;
	@media (max-width: 480px){
		transition: all 0.4s ease;
		&.back {
			transform: translate(-100%, 0);
		}
		&.forward {
			transform: translate(100%, 0);
		}
		&.show {
			transform: translate(0, 0);
			transition: all 0.4s ease;
		}
	}
	&.locked {
		z-index: -1;
	}
}

main {
	flex: 1 1 auto;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
:focus-visible {
	outline: none;
}
.container {
	max-width: $mw;
	margin: 0 auto;
	@media (max-width: $md1) {
		max-width: 1100px;
	}
	@media (max-width: $md2) {
		max-width: 950px;
	}
	@media (max-width: $md3) {
		max-width: 750px;
	}
	@media (max-width: $md4) {
		max-width: none;
		padding: 0 24px;
	}
}
