.section {
   &__note {
      padding: 21px 0;
      display: none;
      @media (max-width: 480px) {
         display: block;
      }
      &-girls {
         .note {
            &__content {
               .note__text {
                  &:nth-child(4),
                  &:nth-child(5) {
                     margin-left: 31px;
                     margin-bottom: 20px;
                  }
               }
            }
         }
      }
      &-missed {
         .note {
            &__content {
               .note__text:nth-child(3) {
                  margin-bottom: 40px !important;
               }
            }
         }
      }
   }
}
.note {
   &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
   }
   &__back {
      display: flex;
      align-items: center;
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 19px;
      line-height: 1.14;
      color: #cfae4e;
      svg {
         width: 8px;
         margin-top: 3px;
         margin-right: 10px;
      }
   }
   &__edit {
      width: 19px;
      img {
         width: 100%;
         height: 100%;
      }
   }
   &__content {
      .note__text:nth-child(2) {
         margin-bottom: 26px;
      }
   }
   &__title {
      font-family: "Abhaya Libre";
      font-style: normal;
      font-weight: 500;
      font-size: 37px;
      line-height: 1.18;
      color: #000000;
      margin-bottom: 17px;
   }
   &__text {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 1.15;
      color: #2e2f2f;
      span {
         color: #fa0b0b;
         text-decoration: underline;
      }
   }
   &__list {
      margin-left: 31px;
      .note__text {
         margin-bottom: 18px !important;
         margin-left: 0;
      }
   }
   &__link {
      color: #cfae4e;
      text-decoration: underline;
   }
}
