.section {
   &__home {
      position: relative;
      padding: 10px 0;
      display: none;
      @media (max-width: 480px) {
         display: block;
      }
   }
}
.home {
   &__header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 19px;
      &.fixed {
         position: sticky;
         left: 0;
         right: 0;
         top: 0;
         z-index: 99;
         padding: 10px 0;
         background: #ffffff;
      }
   }
   &__switch {
      display: flex;
      align-items: center;
      background: #edeeee;
      border-radius: 10px;
      padding: 4px;
      min-width: fit-content;
      max-width: 174px;
      width: 100%;
      margin: 0 auto;
      transform: translate(19%, 0);
      @media (max-width: 375px){
         transform: translate(8%, 0);
      }
   }
   &__switch-item {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 1.15;
      color: #000000;
      padding: 5px 0 6px;
      max-width: 81px;
      width: 100%;
      text-align: center;
      margin-right: 12px;
      &:last-child {
         margin-right: 0;
      }
      &.active {
         font-family: "SF Bold";
         font-weight: 700;
         background: #ffffff;
         border-radius: 5px;
      }
   }
   &__link {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 1.14;
      color: #4186f5;
   }
   &__title {
      font-family: "SF Bold";
      font-weight: 700;
      font-size: 28px;
      line-height: 1.15;
      color: #000000;
      margin-bottom: 26px;
   }
   &__item {
      padding: 0 24px 9px 0;
      margin-bottom: 13px;
      border-bottom: 1px solid #e2e3e3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: -24px;
   }
   &__item-title {
      font-family: "SF Bold";
      font-weight: 700;
      font-size: 17px;
      line-height: 1.15;
      color: #000000;
      margin-bottom: 3px;
      &.missed {
         color: #eb5445;
      }
   }
   &__item-text {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 1.15;
      color: #89898d;
   }
   &__item-menu {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .home__item-text {
         margin-right: 15px;
      }
   }
   &__item-info {
      width: 19px;
      height: 20px;
      img {
         width: 100%;
      }
   }
}
