.section {
   &__language {
      display: none;
      padding: 23px 0;
      @media (max-width: 480px) {
         display: block;
      }
   }
}
.language {
   &__form {
      display: flex;
      align-items: center;
      padding-bottom: 17px;
      margin-bottom: 11px;
      border-bottom: 1px solid #ededed;
   }
   &__form-input {
      width: 100%;
      background: #f2f2f2;
      border-radius: 11.5px;
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 19px;
      line-height: 1.14;
      color: #000000;
      border: none;
      padding: 8px 16px 13px;
      margin-right: 13.5px;
      &::placeholder{
         color: #b4adad;
      }
      &::-moz-placeholder{
         color: #b4adad;
         opacity: 1;
      }
   }
   &__form-btn {
      background: none;
      border: none;
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 19px;
      line-height: 1.14;
      color: #3b81f7;
   }
   &__item {
      display: flex;
      flex-direction: column;
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 20px;
      line-height: 1.15;
      color: #000000;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid #DECACA;
   }
   &__item-text {
      font-family: "SF Regular";
      font-weight: 400;
      font-size: 15px;
      line-height: 1.15;
      color: #000000;
      margin-top: 3px;
   }
}
